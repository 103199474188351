<template>
  <section class="promo-section">
    <h2>Токаш Акції</h2>
    <swiper
      :slides-per-view="1"
      :space-between="30"
      :breakpoints="breakpoints"
      :modules="modules"
      navigation
      pagination
      class="mySwiper"
      ref="mySwiper"
    >
      <swiper-slide v-for="(item, index) in items" :key="index" class="swiper-slide">
        <div class="promo-item">
          <img :src="item.image" :alt="item.title">
          <div class="promo-text">
            <h3>{{ item.title }}</h3>
            <p>{{ item.description }}</p>
          </div>
        </div>
      </swiper-slide>
      <div class="swiper-pagination"></div>
      <div class="swiper-button-next"></div>
      <div class="swiper-button-prev"></div>
    </swiper>
  </section>
</template>








<script>
import { Swiper, SwiperSlide , useSwiper} from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation, Pagination } from 'swiper/modules';
import { ref } from 'vue';

export default {
  components: {
    Swiper,
    SwiperSlide
  },
  setup() {
    const mySwiper = ref(null);

    const slideNext = () => {
      console.log("next")
      useSwiper.slideNext;
    };

    const slidePrev = () => {
      mySwiper.value.swiper.slidePrev();
    };

    return {
      mySwiper,
      slideNext,
      slidePrev,
      modules: [Navigation, Pagination],
    };
  },
  data() {
    return {
      breakpoints: {
        600: {
          slidesPerView: 1,
          spaceBetween: 10
        },
        900: {
          slidesPerView: 2,
          spaceBetween: 20
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 30
        }
      },
      items: [
        {
          image: require('@/assets/sales/take_away.png'),
          title: "Забери сам",
          description: "При замовленні онлайн забираєте замовлення самостійно 10% знижка"
        },
        {
          image: require('@/assets/sales/happy_birthday.png'),
          title: "На День Народження",
          description: "При пред'явленні документу з підтвердженням дати народження 20%"
        },
        // {
        //   image: require('@/assets/sales/instagram_repost.jpeg'),
        //   title: "Відмітка нас в сторіс",
        //   description: "При згадці в сторіс будь-яких соціальних мереж знижка 15%"
        // },
        // {
        //   image: require('@/assets/sales/fisht_day.jpeg'),
        //   title: "Рибний день із 'Токаш'",
        //   description: "При замовленні філадельфії лосось в четвер отримайте ще 1 ролл"
        // },
        // {
        //   image: require('@/assets/sales/happy_hour.jpeg'),
        //   title: "Щасливі години",
        //   description: "З 10 ранку до 12 години при замовленні ролів акційних знижка 30%"
        // },
        // {
        //   image: require('@/assets/sales/friend.png'),
        //   title: "Приведи друга",
        //   description: "Приведи друга та отримай 15% на наступне замовлення при замовленні на суму від 500 грн"
        // },
        // {
        //   image: require('@/assets/sales/bonus.png'),
        //   title: "Суші бонус",
        //   description: "Отримай безкоштовний ролл при замовленні від 800 гривень"
        // },
        {
          image: require('@/assets/sales/student.jpeg'),
          title: "Студентський бум",
          description: "При пред'явленні студентського квитка 15% знижка"
        },
        // {
        //   image: require('@/assets/sales/first_time.png'),
        //   title: "Перший раз?",
        //   description: "Для нових клієнтів ролл 'Токаш' у подарунок"
        // },
        // {
        //   image: require('@/assets/sales/one_plus_one.jpeg'),
        //   title: "Акція 1+1=3",
        //   description: "При замовленні двох ролів - третій у подарунок"
        // }
      ]
    };
  }
};

</script>

<style scoped>
.promo-section {
  padding: 20px;
  text-align: center;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Ensure slides take full height */
}

.promo-item {
  background-color: #333;
  color: #E9E9E9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; /* Ensure the promo item takes full height */
  width: 100%; /* Ensure promo items fill the slide */
  max-width: 300px; /* Adjust as needed to fit your design */
  box-sizing: border-box;
}

.promo-item img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: 200px; /* Adjust height as needed */
  object-fit: cover;
}

.promo-text {
  margin-top: auto;
  text-align: left; /* Ensure the text remains left-aligned */
}

.swiper-button-prev {
  background-image: url('@/assets/left_arrow.svg'); /* Path to your SVG file */
}

.swiper-button-next {
  background-image: url('@/assets/right_arrow.svg'); /* Path to your SVG file */
}

.swiper-button-prev::after,
.swiper-button-next::after {
  content: ''; /* Remove default icons */
  /* background-image: url('@/assets/right_arrow.svg'); */
}

.swiper-button-next,
.swiper-button-prev {
  color: #e91e63;
  display: none;
}

.swiper-pagination-bullet {
  background: #e91e63;
}


</style>


