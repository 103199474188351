<template>
  <div id="app">
    <SushiPopup :visible="isPopupVisible" @close-popup="isPopupVisible = false" />
    <header class="header">
      <div class="logo" @click="navigateToMainPage">
        <img src="./assets/logo_compress.png" alt="Суші Токаш">
        <span>Суші Токаш</span>
      </div>
      <nav class="navigation" :class="{ open: isMenuOpen }">
        <ul>
          <li><a href="#">Меню</a></li>
          <li><a href="#sales">Акції</a></li>
          <li><a href="#about">Про нас</a></li>
          <li><a href="#contact">Контакти</a></li>
        </ul>
      </nav>
      <div class="icons"></div>
      <div class="burger-menu" @click="toggleMenu">
        <div :class="{ 'burger-bar': true, 'open': isMenuOpen }"></div>
        <div :class="{ 'burger-bar': true, 'open': isMenuOpen }"></div>
        <div :class="{ 'burger-bar': true, 'open': isMenuOpen }"></div>
      </div>
    </header>
    <div class="main-content">
      <div class="cart-button" @click="toggleCart">
        <span class="cart-icon">🛒</span>
        <span class="cart-count">{{ totalItems }}</span>
      </div>
      <transition name="slide">
        <div v-if="showCart" class="cart-container">
          <ShoppingCart 
            :cartItems="cartItems" 
            @update-cart="updateCart" 
            @order-submitted="handleOrderSubmitted" 
            @close-cart="toggleCart" 
            @clear-cart="clearCart" />
        </div>
      </transition>
      <RouterViewWrapper @add-to-cart="addToCart"></RouterViewWrapper>
    </div>
    <footer class="footer">
      <div class="footer-content">
        <div class="footer-logo" @click="navigateToMainPage">
          <img src="./assets/logo_compress.png" alt="Суші Токаш Logo">
          <h2>Суші Токаш</h2>
          <p>справжня ролотерапія!</p>
          <div class="app-buttons"></div>
        </div>
        <div class="footer-nav">
          <h3>Навігація:</h3>
          <ul>
            <li><a href="#">Меню</a></li>
            <li><a href="#about">Про нас</a></li>
            <li><a href="#sales">Акції</a></li>
            <li><a href="#contact">Контакти</a></li>
          </ul>
        </div>
        <div class="footer-info">
          <h3>Зробити замовлення:</h3>
          <p><a href="tel:+380800338105">0 800 338 105</a></p>
          <h3 class="margin-top-h">Години роботи ресторану:</h3>
          <p>10:00 - 22:00</p>
          <h3 class="margin-top-h">Час онлайн-замовлень:</h3>
          <p>11:00 - 21:30</p>
          <p class="margin-top-h"> <a href="https://maps.app.goo.gl/ZL3VHh7NsPYLDGrh9">Вул. Осокорська 2 А Кафе «Шиномонтаж» 5 підʼїзд</a></p>
        </div>
        <div class="footer-social">
          <h3>Соціальні мережі:</h3>
          <div class="social-icons">
            <a href="https://www.facebook.com/sushitokash/"><img src="./assets/facebook.png" alt="Facebook"></a>
            <a href="https://www.instagram.com/sushitokash/"><img src="./assets/instagram.png" alt="Instagram"></a>
            <a href="https://www.tiktok.com/@sushitokash1"><img src="./assets/TikTok.svg" alt="TikTok"></a>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import axios from 'axios';
//import HelloWorld from './components/HelloWorld.vue'
import { sushiList } from './components/SushiItem.vue';
import ShoppingCart from './components/ShoppingCart.vue';
import RouterViewWrapper from './components/RouterViewWrapper.vue';
import SushiPopup from './components/SushiPopup.vue';

export default {
  name: 'App',
  components: {
    ShoppingCart,
    RouterViewWrapper,
    SushiPopup
    //HelloWorld
  },
  computed: {
    totalItems() {
      return this.cartItems.reduce((total, item) => total + item.quantity, 0);
    }
  },
  data() {
    return {
      isMenuOpen: false,
      sushiList: sushiList,
      cartItems: [],
      showCart: false,
      isPopupVisible: false, // Add this to control the popup visibility
      isOverloaded: false
    };
  },
  mounted() {
    document.addEventListener('click', this.handleOutsideClick);
    window.addEventListener('scroll', this.handleScroll);
    this.checkWorkingHours();
    this.checkOverloadStatus();
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleOutsideClick);
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    checkOverloadStatus() {
      axios
        .get('https://api.sushitokash.com.ua/api/status/ov_s')
        .then((response) => {
          if (response.data === true) {
            this.isOverloaded = true;
          } else {
            this.isOverloaded = false;
          }
        })
        .catch((error) => {
          console.error('Error fetching overload status:', error);
        });
    },
    navigateToMainPage() {
      window.location.href = '/';
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
      this.showCart = false;
    },
    handleOutsideClick(event) {
      const menu = this.$el.querySelector('.navigation');
      const burger = this.$el.querySelector('.burger-menu');
      if (!menu.contains(event.target) && !burger.contains(event.target)) {
        this.isMenuOpen = false;
      }
    },
    handleScroll() {
      this.isMenuOpen = false;
    },
    toggleCart() {
      this.showCart = !this.showCart;
    },
    checkWorkingHours(){
      const now = new Date().toLocaleString("en-US", { timeZone: "Europe/Kyiv" });
      const currentDate = new Date(now);
      const currentHour = currentDate.getHours();
      const currentMinute = currentDate.getMinutes();

      // Define operating hours
      const openingHour = 11;
      const closingHour = 21;
      const closingMinute = 30;

      // Check if it's outside of operating hours
      if (currentHour < openingHour || (currentHour === openingHour && currentMinute < 0) ||
          currentHour > closingHour || (currentHour === closingHour && currentMinute > closingMinute)) {
        // Show the popup if it's outside operating hours
        this.isPopupVisible = true;
        return;
      }
    },
    addToCart(item) {
      const now = new Date().toLocaleString("en-US", { timeZone: "Europe/Kyiv" });
      const currentDate = new Date(now);
      const currentHour = currentDate.getHours();
      const currentMinute = currentDate.getMinutes();

      // Define operating hours
      const openingHour = 11;
      const closingHour = 21;
      const closingMinute = 30;

      // Check if it's outside of operating hours
      if (currentHour < openingHour || (currentHour === openingHour && currentMinute < 0) ||
          currentHour > closingHour || (currentHour === closingHour && currentMinute > closingMinute)) {
        // Show the popup if it's outside operating hours
        this.isPopupVisible = true;
        return;
      }
      if(this.isOverloaded === true){
        this.isPopupVisible = true;
        return;
      }

      console.log("addToCart APP");
      const cartItem = this.cartItems.find(cartItem => cartItem.article === item.article);
      if (cartItem) {
        cartItem.quantity++;
      } else {
        this.cartItems.push({ ...item, quantity: 1 });
      }
    },
    updateCart(updatedItem) {
      const index = this.cartItems.findIndex(item => item.article === updatedItem.article);
      if (index !== -1) {
        this.cartItems.splice(index, 1, updatedItem);
      }
    },
    handleOrderSubmitted() {
      this.cartItems = [];
      this.showCart = false;
      alert('Дякуємо за замовлення, з вами св\'яжеться оператор для підтвердження');
    },
    clearCart() {
      this.cartItems = [];
    }
  }
}
</script>

<style>
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  scroll-behavior: smooth;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#app {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #353535;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 60px; /* Adjust based on the height of your fixed header */
}

.footer {
  background-color: #333;
  padding: 20px 0;
  color: #E9E9E9;
  text-align: center;
  width: 100%;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}
.footer-logo img {
  max-width: 150px; /* Set the maximum width for the logo */
  height: auto; /* Maintain the aspect ratio */
  display: block; /* Ensures proper alignment */
  margin: 0 auto; /* Center the image horizontally */
}
.footer-logo, .footer-nav, .footer-info, .footer-social {
  flex: 1;
}

.footer-nav ul {
  list-style-type: none;
  padding: 0;
}

.footer-nav ul li {
  margin: 5px 0;
}

.footer-nav ul li a {
  color: #E9E9E9;
  text-decoration: none;
}

.footer-nav ul li a:hover {
  text-decoration: underline;
}

.social-icons a {
  margin-right: 10px;
}

.social-icons img {
  width: 30px;
  height: 30px;
}

/* Other existing styles... */


@media (max-width: 600px) {
  .navigation,
  .icons {
    display: none;
  }
}


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #E9E9E9;
  height: 100%;
  margin: 0;
}

body {
  background: url('assets/Group75-min(1).png') center center repeat-y, #202020;
  background-size: cover;
  background-repeat: repeat-y;
  background-position: center center;
  flex-direction: column;
  margin: 0;
  padding: 0;
  color: #E9E9E9;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #353535;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.icons {
  margin-right: 20px;
      min-width: 160px;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  width: 50px;
  height: auto;
  margin-right: 10px;
}

.logo span {
  font-size: 24px;
  font-weight: bold;
  color: #E9E9E9;
}

.navigation ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.navigation ul li {
  margin: 0 15px;
}

.navigation ul li a {
  color: #E9E9E9;
  text-decoration: none;
  font-size: 18px;
}

.icons img {
  width: 30px;
  height: auto;
  margin-left: 10px;
}

.burger-menu {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 24px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  padding-right: 20px;
  z-index: 1001; /* Ensure it's above the nav */
}

.burger-bar {
  width: 30px;
  height: 3px;
  background-color: #A0D172;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.burger-bar.open:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.burger-bar.open:nth-child(2) {
  opacity: 0;
}

.burger-bar.open:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

/* Media queries for mobile */
@media (max-width: 600px) {
  .navigation {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #353535;
  }

  .navigation.open {
    display: flex;
  }

  .burger-menu {
    display: flex;
  }

  .navigation ul {
    flex-direction: column;
    align-items: center;
  }

  .navigation ul li {
    margin: 10px 0;
  }
}



.sushi-section, .app-section, .promo-section {
  margin: 20px auto;
  padding: 20px;
  width: 80%;
}

.sushi-section h2, .app-section h2, .promo-section h2 {
  color: #E9E9E9;
  margin-bottom: 20px;
  text-align: left;
}
.sushi-types-container {
  display: flex;
  justify-content: center;
  margin-top: 80px; /* Adjust this margin to avoid overlap with fixed header */
  width: 100%;
}

.sushi-types {
  display: flex;
  justify-content: space-between;
  width: 50%;  /* Set block width to 50% of the parent container */
  background-color: #333;
  padding: 5px 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.sushi-types a {
  text-decoration: none; /* Removes the underline */
  color: inherit; /* Inherits the color from the parent element */
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1; /* Allows the items to grow and take up equal space */
}

.sushi-types a img {
  width: 40px;
  height: 40px;
  margin-bottom: 5px;
}

.sushi-types a span {
  margin-top: 5px;
  font-size: 14px;
  color: #E9E9E9;
}


.sushi-section {
  margin: 20px auto;
  padding: 20px;
  width: 80%;
  /* background-color: #222;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
}

.sushi-section h2 {
  color: #E9E9E9;
  margin-bottom: 20px;
  text-align: left;
}

.sushi-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}
.sushi-item {
  background-color: #333;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sushi-item img {
  width: 100%;
  height: auto;
}

.sushi-item p {
  padding: 10px;
  color: #E9E9E9;
}

.sushi-item h3 {
  padding: 10px;
  color: #E9E9E9;
}

.button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.price {
  font-size: 18px;
  color: #FF5C5C;
  font-weight: bold;
}

.sushi-item button {
  background-color: #88c057;  /* Light green background color */
  color: #333;  /* Darker text color for contrast */
  border: none;
  border-radius: 20px;
  padding: 10px;
  margin-left: 10px;
  cursor: pointer;
  width: 100px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  position: relative;
}

.sushi-item button:hover {
  background-color: #7bb249;  /* Slightly darker green on hover */
}

.sushi-item button img {
  width: 20px;
  height: 20px;
  margin-left: 5px;
}


.app-section {
  margin: 20px auto;
  padding: 20px;
  width: 80%;
}

.app-section h2 {
  color: #E9E9E9;
  margin-bottom: 20px;
  text-align: left;
}

.app-download {
  display: flex;
  align-items: center;
  gap: 20px;
  background-color: #333;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
}

.app-info {
  flex: 1;
  color: #E9E9E9;
}

.app-info h3 {
  margin-top: 0;
  font-size: 1.2em; /* Decreased font size */
}

.app-info p {
  margin: 10px 0;
  font-size: 0.9em; /* Decreased font size */
}

.app-info button {
  background-color: #88c057;
  color: #333;
  border: none;
  border-radius: 20px;
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.app-info button:hover {
  background-color: #7bb249;
}

.app-info button img {
  width: 20px;
  height: 20px;
  margin-left: 5px;
}

.app-image {
  flex: 1;
  max-width: 50%; /* Ensure the image takes half of the parent width */
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}



/* Media Queries */
@media (max-width: 1024px) {
  .icons {
    min-width: 70px;
  }
  .app-download {
    flex-direction: column;
  }

  .app-image {
    max-width: 100%; /* Full width on smaller screens */
    margin-top: 20px;
  }
}

@media (max-width: 768px) {
  .app-info h3 {
    font-size: 1em; /* Smaller font size for tablets */
  }

  .app-info p {
    font-size: 0.8em; /* Smaller font size for tablets */
  }

  .app-info button {
    font-size: 12px; /* Smaller font size for tablets */
  }
  .icons {
    min-width: 20px;
  }
}

@media (max-width: 480px) {
  .app-info h3 {
    font-size: 0.9em; /* Smaller font size for mobile */
  }

  .app-info p {
    font-size: 0.7em; /* Smaller font size for mobile */
  }

  .app-info button {
    font-size: 10px; /* Smaller font size for mobile */
    padding: 8px;
  }

  .app-download {
    padding: 10px;
  }
}





.footer {
  background-color: #333;
  padding: 20px 0;
  color: #E9E9E9;
  text-align: center;
}

.footer a {
  color: #E9E9E9;
  text-decoration: none;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-logo {
  flex: 1;
  text-align: left;
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically */
  text-align: center;
}

.footer-logo h2 {
  margin: 10px 0;
}

.app-buttons img {
  width: 120px;
  margin: 10px 5px 0 0;
}

.footer-nav,
.footer-info,
.footer-social {
  flex: 1;
  text-align: left;
}

.footer-nav h3,
.footer-info h3,
.footer-social h3 {
  margin-top: 0;
}

.footer-nav ul {
  list-style-type: none;
  padding: 0;
}

.footer-nav ul li {
  margin: 5px 0;
}

.footer-nav ul li a {
  color: #E9E9E9;
  text-decoration: none;
}

.footer-nav ul li a:hover {
  text-decoration: underline;
}

.footer-info p {
  margin: 5px 0;
}

.social-icons a {
  margin-right: 10px;
}

.social-icons img {
  width: 30px;
  height: 30px;
}


@media (max-width: 1300px) {
  .cart-container {
    width: 40% !important;
  }
}

@media (max-width: 1000px) {
  .cart-container {
    width: 50% !important;
  }
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    text-align: center;
  }
  .footer-social{
    text-align: right;
  }

  .footer-logo,
  .footer-nav,
  .footer-info,
  .footer-social {
    margin-bottom: 20px;
  }

  .cart-container {
    width: 70% !important;
  }
}


@media (max-width: 360px) {
.cart-container {
    width: 90% !important;
  }
}



.cart-button {
  position: fixed;
  top: 160px;
  right: 0;
  background-color: #333;
  color: #fff;
  border: none;
  padding: 8px 16px;
  font-size: 20px;
  border-radius: 20px 0 0 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
}

.cart-button .cart-count {
  background-color: #88c057;
  color: #fff;
  border-radius: 50%;
  padding: 1px 5px;
  top: -6px;
  right: 2px;
  position: relative;
  font-size: 15px;
}

.cart-container {
  position: fixed;
  top: 60px;
  right: 0;
  width: 30%;
  max-height: 85%;
  background: url('assets/Group75-min(1).png') center center repeat-y, #202020;
  background-size: cover;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  background-size: 310%;
  z-index: 1001;
  overflow-y: auto;
  padding: 16px;
  border-radius: 20px 0 0 20px;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.3s ease-in-out;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
}



.margin-top-h {
  margin-top: 14px !important;
  margin-bottom: 6px;

}

</style>
