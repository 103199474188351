<template>
  <section class="promo-section">
    <h2>Токаш Акції</h2>
    <div class="promo-grid">
      <div class="promo-item item1">
        <img src=".././assets/sales/take_away.png" alt="Забери сам">
        <h3>Забери сам</h3>
        <p>При замовленні онлайн забираєте замовлення самостійно 10% знижка</p>
      </div>
      <div class="promo-item item2">
        <img src=".././assets/sales/happy_birthday.png" alt="На День Народження">
        <h3>На День Народження</h3>
        <p>При пред'явленні документу з підтвердженням дати народження 20%</p>
      </div>
      <div class="promo-item item3">
        <img src=".././assets/sales/student.jpeg" alt="Студентський бум">
        <h3>Студентський бум</h3>
        <p>При пред'явленні студентського квитка 15% знижка</p>
      </div>
      <!-- <div class="promo-item item1">
        <img src=".././assets/sales/take_away.png" alt="Забери сам">
        <h3>Забери сам</h3>
        <p>При замовленні онлайн забираєте замовлення самостійно 10% знижка</p>
      </div>
      <div class="promo-item item2">
        <img src=".././assets/sales/happy_birthday.png" alt="На День Народження">
        <h3>На День Народження</h3>
        <p>При пред'явленні документу з підтвердженням дати народження 20%</p>
      </div>
      <div class="promo-item item3">
        <img src=".././assets/sales/instagram_repost.jpeg" alt="Відмітка нас в сторіс">
        <h3>Відмітка нас в сторіс</h3>
        <p>При згадці в сторіс будь-яких соціальних мереж знижка 15%</p>
      </div>
      <div class="promo-item item4">
        <img src=".././assets/sales/fisht_day.jpeg" alt="Рибний день із 'Токаш'">
        <h3>Рибний день із 'Токаш'</h3>
        <p>При замовленні філадельфії лосось в четвер отримайте ще 1 ролл</p>
      </div>
      <div class="promo-item item5">
        <img src=".././assets/sales/happy_hour.jpeg" alt="Щасливі години">
        <h3>Щасливі години</h3>
        <p>З 10 ранку до 12 години при замовленні ролів акційних знижка 30%</p>
      </div>
      <div class="promo-item item6">
        <img src=".././assets/sales/friend.png" alt="Приведи друга">
        <h3>Приведи друга</h3>
        <p>Приведи друга та отримай 15% на наступне замовлення при замовленні на суму від 500 грн</p>
      </div>
      <div class="promo-item item7">
        <img src=".././assets/sales/bonus.png" alt="Суші бонус">
        <h3>Суші бонус</h3>
        <p>Отримай безкоштовний ролл при замовленні від 800 гривень</p>
      </div>
      <div class="promo-item item8">
        <img src=".././assets/sales/student.jpeg" alt="Студентський бум">
        <h3>Студентський бум</h3>
        <p>При пред'явленні студентського квитка 15% знижка</p>
      </div>
      <div class="promo-item item9">
        <img src=".././assets/sales/first_time.png" alt="Перший раз?">
        <h3>Перший раз?</h3>
        <p>Для нових клієнтів ролл 'Токаш' у подарунок</p>
      </div>
      <div class="promo-item item10">
        <img src=".././assets/sales/one_plus_one.jpeg" alt="Акція 1+1=3">
        <h3>Акція 1+1=3</h3>
        <p>При замовленні двох ролів - третій у подарунок</p>
      </div> -->
    </div>
  </section>
</template>

<script>
export default {
  name: "About-us",
  props: {
    msg: String,
  },
  created() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  },
};
</script>

<style scoped>
.promo-section {
  padding: 20px;
  padding-top: 40px;
  text-align: center;
}

.promo-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.promo-item {
  background-color: #333;
  color: #E9E9E9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.promo-item img {
  width: 80%;
  height: auto;
  margin: 0 auto 20px;
}

.promo-item h3, .promo-item p {
  width: 100%;
  text-align: left;
}

@media (max-width: 768px) {
  .promo-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}

@media (max-width: 600px) {
  .promo-grid {
    grid-template-columns: 1fr;
  }
}

</style>
