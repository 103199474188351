<template>
  <section class="about-us">
    <h2>Про нас</h2>
    <p>
      Ласкаво просимо до Токаш — місця, де японська кулінарія стає справжнім
      мистецтвом! Наш ресторан заснований з метою привнести найкращі традиції
      японської кухні до нашого міста.
    </p>
  </section>
  <section class="about-us t2">
    <h2>Наша місія</h2>
    <p>
      Ми прагнемо створити унікальний гастрономічний досвід для кожного гостя, використовуючи тільки найсвіжіші інгредієнти та автентичні рецепти. Ми віримо, що кожна страва повинна бути витвором мистецтва, який приносить радість і задоволення.
    </p>
  </section>

  <section class="about-us t2">
    <h2>Наша філософія</h2>
    <p>
      Наш підхід базується на трьох ключових принципах: свіжість, якість та традиції. Ми дотримуємося автентичних рецептів та використовуємо тільки найсвіжіші інгредієнти, щоб створити справжні кулінарні шедеври. Наша мета — запропонувати вам автентичний смак Японії, поєднаний із сучасними кулінарними тенденціями.
    </p>
  </section>

  <section class="about-us t2">
    <h2>Команда</h2>
    <p>
      Наші шеф-кухарі — це справжні професіонали своєї справи. Вони працюють з пристрастю та майстерністю, щоб кожна страва була ідеальною. Кожен член нашої команди прагне зробити ваш візит до нашого ресторану особливим та незабутнім.
    </p>
  </section>


  <section class="why-choose-us">
    <h2>Чому обрати саме нас</h2>
    <div class="reasons-grid">
      <div class="reason-item">
        <img src=".././assets/only_fresh.png" alt="Тільки свіжі складники" />
        <h3>Тільки свіжі складники</h3>
      </div>
      <div class="reason-item">
        <img src=".././assets/get_deal.png" alt="Вигідні акції" />
        <h3>Вигідні акції</h3>
      </div>
      <div class="reason-item">
        <img src=".././assets/mobile_app.png" alt="Додатки для iOS та Android" />
        <h3>Додатки для iOS та Android</h3>
      </div>
    </div>
  </section>

  <section class="about-us t2">
    <h2>Наші послуги</h2>
    <p>
      Окрім традиційного обслуговування в ресторані, ми пропонуємо послуги доставки, щоб ви могли насолоджуватися нашими стравами у зручний для вас час та місце. Ми також організовуємо банкетні заходи та корпоративні вечірки, щоб зробити ваші особливі моменти ще більш незабутніми.
    </p>
  </section>


<section class="about-us t2">
    <h2>Запрошуємо Вас!</h2>
    <p>
      Приходьте до Токаш та насолоджуйтесь справжнім смаком Японії. Ми впевнені, що ви залишитеся задоволені та захочете повернутися знову. Наша команда завжди готова забезпечити вам найкращий сервіс та незабутні гастрономічні враження.
Ми дякуємо вам за вибір нашого ресторану і чекаємо на ваш візит!
</p>
  </section>

</template>

<script>
export default {
  name: "About-us",
  props: {
    msg: String,
  },
  created() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  },
};
</script>

<style scoped>
.about-us {
  text-align: left;
  padding: 20px;
  margin-top: 40px;
  padding-left: 10%;
  padding-right: 10%;
}
.t2{
  margin-top: 10px !important;
  padding-top: 0px;
}
.about-us h2,
.why-choose-us h2 {
  color: #e9e9e9;
  margin-bottom: 20px;
}

.about-us p {
  max-width: 600px;
  /* margin: 0 auto; */
  color: #e9e9e9;
  text-align: left;
}

.why-choose-us {
  padding: 20px;
  padding-left: 10%;
  padding-right: 10%;
  text-align: left;
}

.why-choose-us .reasons-grid {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.reason-item {
  background-color: #333;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 100%;
  max-width: 300px;
}

.reason-item img {
  width: 100%;
  height: auto;
}

.reason-item h3 {
  padding: 10px;
  color: #e9e9e9;
}

@media (max-width: 768px) {
  .about-us p,
  .why-choose-us h2,
  .reason-item h3 {
    font-size: 16px;
  }

  .why-choose-us .reasons-grid {
    flex-direction: column;
    align-items: center;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .why-choose-us .reasons-grid {
    flex-wrap: wrap;
    justify-content: space-around;
  }
}
</style>