// src/router.js

import { createRouter, createWebHashHistory } from 'vue-router';
import HelloWorld from './components/HelloWorld.vue';
import About from './components/About.vue';
import Sales from './components/Sales.vue';
import SushiItem from './components/SushiItem.vue';
import Polityka from './components/Polityka.vue';
import Contact from './components/Contact.vue';

const routes = [
  { path: '/', component: HelloWorld },
  { path: '/about', component: About },
  { path: '/sales', component: Sales },
  { path: '/polityka', component: Polityka},
  { path: '/contact', component: Contact},
  {
    path: '/sushi-item/:article',
    name: 'SushiItem',
    component: SushiItem,
    props: true
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;
