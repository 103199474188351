<template>
  <button class="close-button" @click="closeCart">×</button>
  <div class="shopping-cart">
    <template v-if="!showForm && !orderSubmitted">
      <div class="cart-header">
        <h2>Корзина</h2>
        <button class="clear-button" @click="clearCart">Очистити</button>
      </div>
      <div class="cart-item" v-for="item in localCartItems" :key="item.article">
        <img :src="item.photo" alt="Image" />
        <div class="item-details">
          <p class="item-name">{{ item.name }}</p>
          <p class="item-weight">{{ item.weight }} грам</p>
          <div class="item-quantity">
            <button @click="updateQuantity(item, -1)">-</button>
            <span>{{ item.quantity }}</span>
            <button @click="updateQuantity(item, 1)" class="btn">+</button>
          </div>
          <p class="item-price">{{ item.price * item.quantity }} грн</p>
        </div>
      </div>
      <div class="cart-summary">
        <p>Загальна вага: {{ totalWeight }} грам</p>
        <p class="total-price">Разом: {{ totalPrice }} грн</p>
      </div>
      <button :class="{ 'disabled': localCartItems.length === 0 }" class="checkout-button" @click="showForm = true"
        :disabled="localCartItems.length === 0">Далі</button>
    </template>
    <template v-else-if="showForm && !orderSubmitted">
      <h2>Оформити замовлення</h2>
      <form @submit.prevent="submitForm">
        <div class="form-group">
          <label>Особисті дані</label>
          <input type="text" v-model="name" placeholder="Ваше Ім'я" maxlength="25" :class="{ 'input-error': !nameValid }" required />
          <input type="text" v-model="phone" placeholder="Ваш номер телефону" maxlength="13" :class="{ 'input-error': !phoneValid }" required @input="formatPhoneNumber" @blur="validatePhoneNumber"/>
        </div>
        <div class="form-group">
          <label>Тип доставки</label>
          <button type="button" @click="setDeliveryType('pickup')"
            :class="{ active: deliveryType === 'pickup' }">самовивіз</button>
          <button type="button" @click="setDeliveryType('delivery')"
            :class="{ active: deliveryType === 'delivery' }">доставка</button>
        </div>

        <div v-if="deliveryType === 'pickup'" class="pickup-address">
          Забрати своє замовлення ви можете за адресою: <a href="https://maps.app.goo.gl/ZL3VHh7NsPYLDGrh9"> Осокорська 2 А Кафе «Шиномонтаж» 5 підʼїзд</a>
        </div>

        <div class="form-group delivery-time-group">
          <label>Час доставки</label>
          <div class="delivery-time-container">
            <div class="delivery-day">
              <label>День<span class="required"></span></label>
              <select v-model="deliveryDay" @change="updateTimeOptions">
                <option value="today">Сьогодні</option>
                <option value="tomorrow">Завтра</option>
                <option value="day_after_tomorrow">Післязавтра</option>
              </select>
            </div>
            <div class="delivery-time">
              <label>Час<span class="required"></span></label>
              <select v-model="deliveryTime">
                <option v-for="time in availableTimes" :key="time" :value="time">{{ time }}</option>
              </select>
            </div>
          </div>
        </div>


        <div v-if="deliveryType === 'delivery'" class="form-group">
          <label>Адреса</label>
          <input type="text" v-model="street" @input="filterStreetOptions" placeholder="Введіть назву вулиці, наприклад: Осокорська" :class="{ 'input-error': !streetValid }" required />
          <div v-if="filteredStreetOptions.length" class="suggestions">
            <p v-for="option in filteredStreetOptions" :key="option" @click="selectStreet(option)">{{ option }}</p>
          </div>
          <input type="text" v-model="house" placeholder="будинок" maxlength="10" :class="{ 'input-error': !houseValid }" required />
          <input type="text" v-model="entrance" placeholder="під'їзд" maxlength="10" />
          <input type="text" v-model="floor" placeholder="поверх" maxlength="3"/>
          <input type="text" v-model="apartment" placeholder="квартира/офіс" maxlength="10"/>
        </div>
        <div class="form-group">
          <label>Спосіб оплати</label>
          <button type="button" @click="setPaymentMethod('card')"
            :class="{ active: paymentMethod === 'card' }">карта</button>
          <button type="button" @click="setPaymentMethod('cash')"
            :class="{ active: paymentMethod === 'cash' }">готівка</button>
        </div>
        <div class="cart-summary">
          <p>Загальна вага: {{ totalWeight }} грам</p>
          <p class="total-price">Разом: {{ totalPrice }} грн</p>
        </div>
        <div class="agreement-section">
          <input type="checkbox" id="agreement" v-model="agreementChecked" />
          <label for="agreement">
            Я погоджуюся з 
            <a href="#/polityka" target="_blank">Договором оферти</a>.
          </label>
        </div>
        <button type="submit" class="checkout-button" :class="{ 'disabled': !isFormValid || isSubmitting }"  :disabled="!isFormValid || isSubmitting">Далі</button>
      </form>
    </template>
    <template v-else>
      <div class="thank-you-message">
        Дякуємо що обрали суші ТОКАШ, оператори зв'яжуться з вами для підтвердження замовлення.
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'ShoppingCart',
  props: {
    cartItems: Array
  },
  data() {
    return {
      //localCartItems: [...this.cartItems],
      showForm: false,
       agreementChecked: true, 
      name: '',
      phone: '+38',
      deliveryType: '',
      street: '',
      isSubmitting: false, 
      house: '',
      entrance: '',
      floor: '',
      apartment: '',
      paymentMethod: '',
      deliveryDay: 'today',
      deliveryTime: 'Найближчий', // Set the default time to "найближчий"
      availableTimes: ["Найближчий"], // Initialize with "найближчий
      streetOptions: [
        'проспект Петра Григоренка', 'вулиця Анни Ахматової', 'вулиця Драгоманова',
        'вулиця Олександра Кошиця', 'вулиця Степана Олійника', 'вулиця Ревуцького',
        'вулиця Бориса Гмирі', 'вулиця Михайла Гришка', 'вулиця Лариси Руденко',
        'вулиця Олександра Мишуги', 'вулиця Соломії Крушельницької', 'проспект Миколи Бажана',
        'Вишняківська вулиця', 'Срібнокільська вулиця', 'вулиця Княжий Затон',
        'Урлівська вулиця', 'Дніпровська набережна', 'вулиця Єлизавети Чавдар',
        'Сортувальна вулиця', 'Центральна вулиця', 'Батуринська вулиця',
        'Здолбунівська вулиця', 'Причальна вулиця', 'вулиця Григорія Ващенка',
        'Позняківська вулиця', '42-га Садова вулиця', 'Зарічна вулиця',
        'Дніпрова вулиця', 'Тальнівська вулиця', 'Заводський провулок',
        'Трускавецька вулиця', 'вулиця Софії Русової', 'вулиця Алімпія Галика',
        'Іжкарська вулиця', 'Осокорський провулок', '49-та Садова вулиця',
        '136-та Садова вулиця', 'Завальна вулиця', '192-га Садова вулиця',
        '138-ма Садова вулиця', '139-та Садова вулиця', '53-тя Садова вулиця',
        '50-та Садова вулиця', 'вул. Осокорська', 'вулиця Маслівка',
        '53-тя А Садова вулиця', '54-та Садова вулиця'
      ],
      filteredStreetOptions: [],
      orderSubmitted: false
    };
  },
  computed: {
    localCartItems: {
      get() {
        return this.cartItems;
      },
      set(updatedItems) {
        this.$emit('update:cartItems', updatedItems);
      }
    },
    totalWeight() {
      return this.localCartItems.reduce((total, item) => total + item.weight * item.quantity, 0);
    },
    totalPrice() {
      return this.localCartItems.reduce((total, item) => total + item.price * item.quantity, 0);
    },
    isFormValid() {
      return this.nameValid && 
            this.phoneValid && 
            (this.deliveryType !== 'delivery' || (this.streetValid && this.houseValid)) &&
            this.agreementChecked; // Додано перевірку згоди з договором оферти
    },

    nameValid() {
      return this.name.length > 0 && this.name.length <= 25;
    },
    phoneValid() {
      const phoneNumber = this.phone.replace(/\D/g, ''); 
      return phoneNumber.length === 12;
    },
    streetValid() {
      return this.street.length > 0;
    },
    houseValid() {
      return this.house.length > 0 && this.house.length <= 10;
    }
  },
  created(){
    this.updateTimeOptions();
  },
  methods: {
    updateTimeOptions() {
      const now = new Date();
      const currentHour = now.getHours();
      const currentMinutes = now.getMinutes();
      const currentTime = currentHour * 60 + currentMinutes;

      const startHour = (this.deliveryDay === 'today') ? Math.ceil((currentTime + 60) / 15) * 15 / 60 : 11.5;
      const endHour = 21.5;

      this.availableTimes = ["Найближчий"];

      for (let time = startHour; time <= endHour; time += 0.25) {
        const hours = Math.floor(time);
        const minutes = (time % 1) * 60;
        const timeString = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
        this.availableTimes.push(timeString);
      }
    },
    setDeliveryType(type) {
      this.deliveryType = type;
      this.updateTimeOptions();
    },
    setPaymentMethod(method) {
      this.paymentMethod = method;
    },
    selectStreet(option) {
      this.street = option;
      this.filteredStreetOptions = [];
    },
    filterStreetOptions() {
      if (this.street) {
        this.filteredStreetOptions = this.streetOptions.filter(option =>
          option.toLowerCase().includes(this.street.toLowerCase())
        );
      } else {
        this.filteredStreetOptions = [];
      }
    },
    updateQuantity(item, amount) {
    const index = this.localCartItems.findIndex(cartItem => cartItem.article === item.article);
    console.log("updateQuantity");
    console.log(this.localCartItems);
    if (index !== -1) {
      const newQuantity = this.localCartItems[index].quantity + amount;
      if (newQuantity > 0) {
        console.log("updateQuantity newQuantity > 0 index" + index);
        this.localCartItems[index].quantity = newQuantity;
      } else {
        console.log("updateQuantity newQuantity = 0 index" + index);
        this.localCartItems.splice(index, 1);
      }
      this.$emit('update:cartItems', [...this.localCartItems]);
    }
  
    },
    async submitForm() {
      this.isSubmitting = true;
      let deliveryDayText = '';
      if (this.deliveryDay === 'today') {
        deliveryDayText = 'Сьогодні';
      } else if (this.deliveryDay === 'tomorrow') {
        deliveryDayText = 'Завтра';
      } else if (this.deliveryDay === 'day_after_tomorrow') {
        deliveryDayText = 'Післязавтра';
      }

      const address = this.deliveryType === 'delivery'
        ? `Вул ${this.street}, ${this.house}, під ${this.entrance}, пов ${this.floor}, кв ${this.apartment}`
        : 'самовивіз';

      const formattedItems = this.localCartItems.map(item =>
        `*Артикул:* ${item.article}\n*Назва:* ${item.name}\n*Ціна:* ${item.price} грн\n*Кількість:* ${item.quantity}`
      ).join('\n\n');

      const paymentMethodText = this.paymentMethod === 'card' ? 'карта' : 'готівка';

      const message = `
Нове замовлення:
*Ім'я:* ${this.name}
*Телефон:* ${this.phone}
*Адреса:* ${address}
*Оплата:* ${paymentMethodText}
*Час доставки:* ${deliveryDayText} ${this.deliveryTime}
*Товари:*
${formattedItems}

*Загальна вага:* ${this.totalWeight} грам
*Загальна вартість:* _${this.totalPrice} грн_`;



      try {
        //const response = await fetch(`https://api.sushitokash.com.ua/test`, {
        //const response = await fetch(`https://api.sushitokash.com.ua/order/create`, {
        const response = await fetch(`https://api.sushitokash.com.ua/order/create`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ text: message })
        });

         // Check if the response status is 200
            if (response.status === 200) {
                this.orderSubmitted = true;
                this.clearCart();
                try{
                  const data = await response.json();
                  console.log(data);
                

                  if (data.paymentUrl) {
                      //window.location.href = data.paymentUrl;
                  }
                } catch (error) {
                    console.error('Cannot parse json:', error); 
                }
                this.orderSubmitted = true;
                this.clearCart();
            } else {
                console.error(`Unexpected response status: ${response.status}`);
                // Handle non-200 status codes here, such as displaying an error message
            }
        } catch (error) {
            console.error('Error submitting order:', error);
        } finally {
          this.isSubmitting = false;  // Enable the button again after response
        }
    },
    closeCart() {
      this.$emit('close-cart');
    },
    clearCart() {
      this.localCartItems = [];
      this.$emit('clear-cart');
    },
    formatPhoneNumber(event) {
      let value = event.target.value;
      if (!value.startsWith('+38')) {
        value = '+38' + value.replace(/[^0-9]/g, '');
      } else {
        value = '+38' + value.substring(3).replace(/[^0-9]/g, '');
      }
      this.phone = value;
    },
    validatePhoneNumber() {
      const phoneNumber = this.phone.replace(/\D/g, ''); 
      if (phoneNumber.length !== 12) {
        alert('Будь ласка, введіть дійсний номер телефону у форматі +38XXXXXXXXXX');
      }
    }
  },
  watch: {
    cartItems: {
      handler(newItems) {
        this.localCartItems = [...newItems];
      },
      deep: true
    }
  }
}
</script>

<style>
.shopping-cart {
  background-color: #7a7a7a;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 16px;
  color: white;
  position: relative;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center; 
}

.shopping-cart h2 {
  text-align: center;
  font-size: 1.5em;
  margin-bottom: 16px;
}

.cart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; 
}

.close-button {
  position: fixed;
  right: 19px;
  background: none;
  border: none;
  color: white;
  font-size: 1.5em;
  cursor: pointer;
  top: 75px;
  z-index: 1002;
}

.clear-button {
  background-color: #ff6666;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 16px;
  font-size: 1em;
  cursor: pointer;
}

.clear-button:hover {
  background-color: #ff4d4d;
}

.cart-item {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  width: 100%; 
}

.cart-item img {
  max-width: 80px;
  border-radius: 8px;
  margin-right: 16px;
}

.item-details {
  flex: 1;
}

.item-name {
  font-size: 1.2em;
  font-weight: bold;
}

.item-weight,
.item-price {
  color: rgb(220, 217, 217);
  margin: 4px 0;
}

.item-quantity {
  display: flex;
  align-items: center;
  margin: 8px 0;
  justify-content: center;
}

.item-quantity button {
  background-color: #88c057;
  color: #fff;
  border: none;
  border-radius: 15px 0 0 15px;
  width: 30px;
  height: 30px;
  font-size: 1em;
  cursor: pointer;
}

.item-quantity .btn {
  border-radius: 0 15px 15px 0;
}

.item-quantity span {
  margin: 0 10px;
  font-size: 1.2em;
}

.cart-summary {
  text-align: left;
  margin-top: 16px;
}

.cart-summary p {
  margin: 4px 0;
  font-weight: bold;
}

.cart-summary .total-price {
  font-size: 25px;
  color: #ff5c5c;
}

.checkout-button {
  background-color: #88c057;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 16px;
  font-size: 1em;
  cursor: pointer;
  width: 40%;
  margin-top: 16px;
}

.checkout-button.disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.checkout-button:hover {
  background-color: #71a543;
}

.form-group {
  margin-bottom: 16px;
  width: 100%; 
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 8px;
}

.form-group input {
  width: 95%;
  padding: 8px;
  margin-bottom: 8px;
  border: 1px solid #ccc;
  border-radius: 18px;
  background-color: #b9cfa6;
}

.form-group input.input-error {
  border-color: red;
}

.form-group button {
  padding: 8px 16px;
  margin-right: 8px;
  border: none;
  background-color: #88c057;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
}

.form-group button.active {
  background-color: #71a543;
}

.suggestions {
  background-color: #88c057;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: -8px;
  margin-bottom: 8px;
  max-height: 100px;
  overflow-y: auto;
}

.suggestions p {
  margin: 0;
  padding: 8px;
  cursor: pointer;
  color: black;
}

.suggestions p:hover {
  background-color: #71a543;
}

.thank-you-message {
  text-align: center;
  font-size: 27px;
  color: white;
}



@media (max-width: 768px) {
  .shopping-cart {
    padding: 12px;
  }

  .shopping-cart h2 {
    font-size: 1.2em;
  }

  .cart-header {
    /* flex-direction: column; */
    align-items: center;
  }

  .clear-button {
    margin-top: 10px;
  }

  .cart-item {
    /* flex-direction: column; */
    align-items: center;
  }

  .cart-item img {
    max-width: 100px;
    margin-bottom: 12px;
  }

  .item-quantity {
    /* flex-direction: row; */
    width: 100%;
    justify-content: space-between;
  }

  .item-quantity span {
    font-size: 1em;
  }

  .checkout-button {
    padding: 8px 12px;
    font-size: 0.9em;
  }

  .form-group input {
    width: 95%;
  }

  .form-group button {
    padding: 8px 12px;
    font-size: 0.9em;
  }

  .suggestions {
    max-height: 80px;
  }

  .thank-you-message {
    font-size: 24px;
  }
}

@media (max-width: 480px) {
  .cart-summary {
    text-align: right;
    margin-top: 16px;
}

  .shopping-cart {
    padding: 8px;
  }

  .shopping-cart h2 {
    font-size: 1em;
  }

  .cart-header {
    /* flex-direction: column; */
    align-items: center;
  }

  .clear-button {
    margin-top: 10px;
  }

  .cart-item {
    /* flex-direction: column; */
    align-items: center;
  }

  .cart-item img {
    max-width: 60px;
  }

  .item-details {
    margin-left: 0;
  }

  .item-quantity {
    width: 100%;
    justify-content: space-between;
  }

  .item-quantity button {
    width: 25px;
    height: 25px;
  }

  .item-quantity span {
    font-size: 1em;
  }

  .checkout-button {
    padding: 8px 10px;
    font-size: 0.8em;
  }

  .form-group input {
    padding: 6px;
  }

  .form-group button {
    padding: 6px 12px;
    font-size: 0.8em;
  }

  .suggestions {
    max-height: 60px;
  }

  .thank-you-message {
    font-size: 20px;
  }
}

.pickup-address {
  margin-top: 10px;
  padding: 10px;
  border-radius: 4px;
  color: #f0f0f0 !important;
  font-size: 14px;
}

.pickup-address a {
  color: #f0f0f0 !important; /* Highlight links with a brighter color */
  text-decoration: none; /* Remove underline from links */
  font-weight: bold; /* Make links bold */
}

.delivery-time-group {
  margin-bottom: 16px;
}

.delivery-time-container {
  display: flex;
  justify-content: space-between;
  gap: 16px; /* Space between the day and time select elements */
}

.delivery-time-container .delivery-day,
.delivery-time-container .delivery-time {
  flex: 1;
}

.delivery-time-container label {
  display: block;
  font-weight: bold;
  margin-bottom: 8px;
  color: #fff; /* Adjust the label color as needed */
}

.delivery-time-container select {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #b9cfa6; /* Match the input field background color */
  color: #333; /* Text color */
  /* font-size: 16px; Font size */
  appearance: none; /* Removes default browser styling */
  cursor: pointer;
}

.delivery-time-container select:focus {
  border-color: #88c057; /* Highlight border color on focus */
  outline: none; /* Removes default focus outline */
}

.required {
  color: red;
  margin-left: 5px;
}


.checkout-button.disabled {
  background-color: #ccc; /* Grey out the button */
  cursor: not-allowed;    /* Change cursor to indicate it's disabled */
}

</style>
