<template>
  <section class="offer-section">
    <section class="offer-section t2">
        <h2>Договір Оферти</h2>
        <p>
        Інтернет-магазин <a href="https://sushitokash.com.ua/" target="_blank">https://sushitokash.com.ua/</a> (далі – Продавець) публікує даний договір, що є публічним договором-офертою і має відповідну юридичну силу, про продаж товарів, представлених на офіційному інтернет-сайті Продавця, на адресу як фізичних, так і юридичних осіб (далі – Покупець).
        </p>
    </section>

    <section class="offer-section t2">
      <h2>1. Загальні положення</h2>
      <p>
        1.1. Відповідно до статей 633, 641 Цивільного Кодексу України (далі – ЦК України) цей Договір є договором публічної оферти (далі – Договір), являє собою офіційну пропозицію інтернет-сайту <a href="https://sushitokash.com.ua/" target="_blank">https://sushitokash.com.ua/</a>, далі іменується як “Продавець”, який здійснює підприємницьку діяльність з продажу Товарів, в тому числі дистанційним способом, представлених на офіційному інтернет-сайті Продавця. Його умови однакові для всіх покупців незалежно від статусу (фізична особа, юридична особа, фізична особа-підприємець).
      </p>
      <!-- Add more content as needed -->
    </section>

    <section class="offer-section t2">
      <h2>2. Предмет Договору</h2>
      <p>
        2.1. Продавець здійснює продаж Покупцю товару відповідно до чинних на момент здійснення замовлення цін, а Покупець здійснює оплату і приймає Товар відповідно до умов даного Договору.
      </p>
      <p>
        2.2. Даний Договір є офіційним документом Продавця та невід’ємною частиною Оферти.
      </p>
    </section>

    <section class="offer-section t2">
      <h2>3. Розміщення Замовлення</h2>
      <p>
        3.1. Замовлення товару здійснюється Покупцем при оформленні через інтернет-сайт Продавця шляхом реєстрації на сайті або за допомогою оператора.
      </p>
      <p>
        3.2. Реєстрація (розміщення) замовлення на сайті означає згоду покупця надати Продавцю реєстраційну інформацію про себе. Покупець висловлює згоду і дозволяє Продавцю та контрагентам Продавця обробляти персональні дані Покупця за допомогою автоматизованих систем управління базами даних, програми “ііко”, а також інших програмних засобів, спеціально розроблених за дорученням Продавця.
      </p>
    </section>

    <!-- Continue adding sections as needed -->
    
    <section class="offer-section t2">
      <h2>8. Інші умови</h2>
      <p>
        8.1. Інтернет-магазин Продавця може бути тимчасово недоступний у зв’язку з проведенням профілактичних чи інших робіт, а також з технічних причин.
      </p>
      <p>
        8.2. З питань, не порушених у даному Договорі, сторони керуються чинним законодавством України.
      </p>
    </section>
  </section>
</template>

<script>
export default {
  name: "OfferAgreement",
  created() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  },
};
</script>

<style scoped>
.offer-section {
  text-align: left;
  padding: 20px;
  margin-top: 40px;
  padding-left: 10%;
  padding-right: 10%;
}

.t2 {
  margin-top: 10px !important;
  padding-top: 0px;
}

.offer-section h2 {
  color: #e9e9e9;
  margin-bottom: 20px;
}

.offer-section p {
  max-width: 600px;
  color: #e9e9e9;
  text-align: left;
}

.offer-section a {
  color: #88c057;
  text-decoration: none;
}

.offer-section a:hover {
  text-decoration: underline;
}
</style>
