<template>
    <div :class="['sushi-types-container', { visible: menuVisible }]">
      <div class="sushi-types">
        <a href="#section-losos" @click.prevent="scrollToSection('section-losos')">
          <img src="../assets/icons/Salmon.svg" alt="Sushi Icon">
          <span>Лосось</span>
        </a>
        <a href="#section-krab" @click.prevent="scrollToSection('section-krab')">
          <img src="../assets/icons/crab.svg" alt="Sushi Icon">
          <span>Краб</span>
        </a>
        <a href="#section-tunets" @click.prevent="scrollToSection('section-tunets')">
          <img src="../assets/icons/tuna.svg" alt="Sushi Icon">
          <span>Тунець</span>
        </a>
        <a href="#section-vugor" @click.prevent="scrollToSection('section-vugor')">
          <img src="../assets/icons/acne.svg" alt="Sushi Icon">
          <span>Вугор</span>
        </a>
        <a href="#section-krevetka" @click.prevent="scrollToSection('section-krevetka')">
          <img src="../assets/icons/shrimp.svg" alt="Sushi Icon">
          <span>Креветка</span>
        </a>
        <a href="#section-gunkani" @click.prevent="scrollToSection('section-gunkani')">
          <img src="../assets/icons/set.svg" alt="Sushi Icon">
          <span>Cети</span>
        </a>
        <a href="#section-dishes" @click.prevent="scrollToSection('section-dishes')">
          <img src="../assets/icons/dishes.svg" alt="Sushi Icon">
          <span>Страви</span>
        </a>
        <a href="#section-drinks" @click.prevent="scrollToSection('section-drinks')">
          <img src="../assets/icons/drink.svg" alt="Sushi Icon">
          <span>Напої</span>
        </a>
      </div>
    </div>


    <section class="sushi-section f-margin" id="section-losos">
      <h2>Суші з лососем</h2>
      <div class="sushi-grid">
        <div class="sushi-item" v-for="item in filteredSushiList('1')" :key="item.article" @click="navigateToSushiItem(item.article)">
          <img :src="item.photo" :alt="item.name">
          <h3 class="m-b">{{ item.name }}</h3>
          <span class="article-number">{{ item.article }}</span>
          <p class="g-weight" v-if="item.weight">{{ item.weight }} грам</p>
          <p class="m-t">{{ item.description }}</p>
          <div class="button-container">
            <span class="price">{{ item.price }} грн</span>
            <button @click.stop="addToCart(item)">Хочу!<img src=".././assets/plus_icon.png" alt="Plus Icon"></button>
          </div>
        </div>
        
      </div>
    </section>

    <section class="sushi-section" id="section-krab">
      <h2>Суші з крабом</h2>
      <div class="sushi-grid">
        <div class="sushi-item" v-for="item in filteredSushiList(['2'])" :key="item.article" @click="navigateToSushiItem(item.article)">
          <img :src="item.photo" :alt="item.name">
          <h3 class="m-b">{{ item.name }}</h3>
          <span class="article-number">{{ item.article }}</span>
          <p class="g-weight" v-if="item.weight">{{ item.weight }} грам</p>
          <p class="m-t">{{ item.description }}</p>
          <div class="button-container">
            <span class="price">{{ item.price }} грн</span>
            <button @click.stop="addToCart(item)">Хочу!<img src=".././assets/plus_icon.png" alt="Plus Icon"></button>
          </div>
        </div>
      </div>
    </section>

    <section class="sushi-section" id="section-tunets">
      <h2>Суші з тунцем</h2>
      <div class="sushi-grid">
        
        <div class="sushi-item" v-for="item in filteredSushiList('3')" :key="item.article" @click="navigateToSushiItem(item.article)">
          <img :src="item.photo" :alt="item.name">
          <h3 class="m-b">{{ item.name }}</h3>
          <span class="article-number">{{ item.article }}</span>
          <p class="g-weight" v-if="item.weight">{{ item.weight }} грам</p>
          <p class="m-t">{{ item.description }}</p>
          <div class="button-container">
            <span class="price">{{ item.price }} грн</span>
            <button @click.stop="addToCart(item)">Хочу!<img src=".././assets/plus_icon.png" alt="Plus Icon"></button>
          </div>
        </div>
      </div>
    </section>

    <section class="sushi-section" id="section-vugor">
      <h2>Ролли з вугрем</h2>
      <div class="sushi-grid">
        <div class="sushi-item" v-for="item in filteredSushiList('4')" :key="item.article" @click="navigateToSushiItem(item.article)">
          <img :src="item.photo" :alt="item.name">
          <h3 class="m-b">{{ item.name }}</h3>
          <span class="article-number">{{ item.article }}</span>
          <p class="g-weight" v-if="item.weight">{{ item.weight }} грам</p>
          <p class="m-t">{{ item.description }}</p>
          <div class="button-container">
            <span class="price">{{ item.price }} грн</span>
            <button @click.stop="addToCart(item)">Хочу!<img src=".././assets/plus_icon.png" alt="Plus Icon"></button>
          </div>
        </div>
      </div>
    </section>

    <section class="sushi-section" id="section-krevetka">
      <h2>Ролли з креветкою</h2>
      <div class="sushi-grid">
        <div class="sushi-item" v-for="item in filteredSushiList('5')" :key="item.article" @click="navigateToSushiItem(item.article)">
          <img :src="item.photo" :alt="item.name">
          <h3 class="m-b">{{ item.name }}</h3>
          <span class="article-number">{{ item.article }}</span>
          <p class="g-weight" v-if="item.weight">{{ item.weight }} грам</p>
          <p class="m-t">{{ item.description }}</p>
          <div class="button-container">
            <span class="price">{{ item.price }} грн</span>
            <button @click.stop="addToCart(item)">Хочу!<img src=".././assets/plus_icon.png" alt="Plus Icon"></button>
          </div>
        </div>
      </div>
    </section>


    <!-- <section class="sushi-section" id="section-sweet">
      <h2>Солодкі</h2>
      <div class="sushi-grid">
      
        
        <div class="sushi-item" v-for="item in filteredSushiList('7')" :key="item.article" @click="navigateToSushiItem(item.article)">
          <img :src="item.photo" :alt="item.name">
          <h3 class="m-b">{{ item.name }}</h3>
          <span class="article-number">{{ item.article }}</span>
          <p class="g-weight" v-if="item.weight">{{ item.weight }} грам</p>
          <p class="m-t">{{ item.description }}</p>
          <div class="button-container">
            <span class="price">{{ item.price }} грн</span>
            <button @click.stop="addToCart(item)">Хочу!<img src=".././assets/plus_icon.png" alt="Plus Icon"></button>
          </div>
        </div>
      </div>
    </section> -->

    <section class="sushi-section" id="section-gunkani">
      <h2>Сети</h2>
      <div class="sushi-grid">
        <div class="sushi-item" v-for="item in filteredSushiList(['6','13'])" :key="item.article" @click="navigateToSushiItem(item.article)">
          <img :src="item.photo" :alt="item.name">
          <h3 class="m-b">{{ item.name }}</h3>
          <span class="article-number">{{ item.article }}</span>
          <p class="g-weight" v-if="item.weight">{{ item.weight }} грам</p>
          <p class="m-t">{{ item.description }}</p>
          <div class="button-container">
            <span class="price">{{ item.price }} грн</span>
            <button @click.stop="addToCart(item)">Хочу!<img src=".././assets/plus_icon.png" alt="Plus Icon"></button>
          </div>
        </div>
      </div>
    </section>

    <section class="sushi-section" id="section-dishes">
      <h2>Страви</h2>
      <div class="sushi-grid">
        <div class="sushi-item" v-for="item in filteredSushiList('8')" :key="item.article" @click="navigateToSushiItem(item.article)">
          <img :src="item.photo" :alt="item.name">
          <h3 class="m-b">{{ item.name }}</h3>
          <span class="article-number">{{ item.article }}</span>
          <p class="g-weight" v-if="item.weight">{{ item.weight }} грам</p>
          <p class="m-t">{{ item.description }}</p>
          <div class="button-container">
            <span class="price">{{ item.price }} грн</span>
            <button @click.stop="addToCart(item)">Хочу!<img src=".././assets/plus_icon.png" alt="Plus Icon"></button>
          </div>
        </div>

      </div>
    </section>

    <section class="sushi-section" id="section-drinks">
      <h2>Напої</h2>
      <div class="sushi-grid">
        <div class="sushi-item" v-for="item in filteredSushiList('12')" :key="item.article" @click="navigateToSushiItem(item.article)">
          <img :src="item.photo" :alt="item.name">
          <h3 class="m-b">{{ item.name }}</h3>
          <span class="article-number">{{ item.article }}</span>
          <p class="g-weight" v-if="item.weight">{{ item.weight }} грам</p>
          <p class="m-t">{{ item.description }}</p>
          <div class="button-container">
            <span class="price">{{ item.price }} грн</span>
            <button @click.stop="addToCart(item)">Хочу!<img src=".././assets/plus_icon.png" alt="Plus Icon"></button>
          </div>
        </div>

      </div>
    </section>

<!-- <section class="sushi-section" id="section-package">
      <h2>Пакет</h2>
      <div class="sushi-grid">
        <div class="sushi-item" v-for="item in filteredSushiList('14')" :key="item.article" @click="navigateToSushiItem(item.article)">
          <img :src="item.photo" :alt="item.name">
          <h3 class="m-b">{{ item.name }}</h3>
          <span class="article-number">{{ item.article }}</span>
          <p class="g-weight">{{ item.weight }} грам</p>
          <p class="m-t">{{ item.description }}</p>
          <div class="button-container">
            <span class="price">{{ item.price }} грн</span>
            <button @click.stop="addToCart(item)">Хочу!<img src=".././assets/plus_icon.png" alt="Plus Icon"></button>
          </div>
        </div>

      </div>
    </section> -->


    <section class="app-section">
      <h2>Додаток</h2>
      <div class="app-download">
        <div class="app-info">
          <h3>Додаток на iOS та Android</h3>
          <p>Бажаєте суші? Завантажте наш мобільний додаток для зручного замовлення. Переглядайте повне меню, налаштовуйте улюблені ролли та робіть замовлення всього кількома натисканнями. Отримуйте ексклюзивні пропозиції, доступні лише в додатку, та відстежуйте доставку в режимі реального часу. Свіжі, смачні суші доставлені прямо до вашого дверей - гарантія задоволення!</p>
          <button @click="installPWA()">Завантажити<img src=".././assets/download_icon.png" alt="Plus Icon"></button>
        </div>
        <img src=".././assets/iso_download.png" alt="App Image" class="app-image">
      </div>
    </section>



    <PromoCarousel />

    <section class="section-map">
      <div class="section-map-header">
        <h2>Перевірте вашу адресу доставки</h2>
        
      </div>
      <div class="map-container">
        <iframe src="https://www.google.com/maps/d/embed?mid=17IbdZ8qoGTUilV9D5Qa2Bbed5buFFCs&ehbc=2E312F" allowfullscreen></iframe>
      </div>


      <!-- <div class="contact-info">
        <div class="contact-item">
          <h3>Адреса</h3>
          <p>м. Київ, вул. Прикладна, 123</p>
        </div>
        <div class="contact-item">
          <h3>Години роботи</h3>
          <p>Пн-Нд: 10:00 - 22:00</p>
        </div>
        <div class="contact-item">
          <h3>Контактний номер</h3>
          <p>+38 (050) 123-45-67</p>
        </div>
      </div> -->
    
    </section>
</template>

<script>
import PromoCarousel from './PromoCarousel.vue';
//import axios from 'axios';

export default {
  name: 'HelloWorld',
  components: {
    PromoCarousel,
  },
  data() {
    return {
      lastScrollTop: 0,
      menuVisible: true,
      deferredPrompt: null,

      sushiList: [
       {
        article: '5009',
        name: 'Рол Lovely',
        weight: '320',
        description: 'Рис, крем-сир, краб, соус шрірача, лосось, кумкват',
        price: '329',
        photo: '/img/sushi/5009_front_mini.jpeg',
        category: '2'
      },
      {
        article: '5002',
        name: 'Рол чіз з крабом',
        weight: '350',
        description: 'Рис, чорнило каракатиці, чеддeр, крем-сир, краб, манго, унагі, ікра тобіко',
        price: '289',
        photo: '/img/sushi/5002_front_mini.jpeg',
        category: '2'
      },
      {
        article: '5005',
        name: 'Каліфорнія з лососем',
        weight: '330',
        description: 'Рис, огірок, авокадо, лосось, ікра тобіко, манго, соус спайсі , солодкий чилі',
        price: '339',
        photo: '/img/sushi/5005_front_mini.jpeg',
        category: '1'
      },
      {
        article: '5016',
        name: 'Філадельфія з лососем',
        weight: '320',
        description: 'Рис, крем-сир, огірок, авокадо, лосось',
        price: '269',
        photo: '/img/sushi/5016_front_mini.jpeg',
        category: '1'
      },
      {
        article: '5011',
        name: 'Макі з лососем і вугрем',
        weight: '250',
        description: 'Рис, норі, лосось, вугор, крем-сир, огірок, манго, авокадо, соус унагі, соус спайсі',
        price: '259',
        photo: '/img/sushi/5011_front_mini.jpeg',
        category: '1'
      },
      {
        article: '5008',
        name: 'Червоний дракон',
        weight: '360',
        description: 'Рис, норі, лосось, огірок, авокадо, ікра тобіко, томаго, зелена цибуля, креветка',
        price: '365',
        photo: '/img/sushi/5008_front_mini.jpeg',
        category: '1'
      },
      {
        article: '5003',
        name: 'Спрінг рол з лососем',
        weight: '230',
        description: 'Рисовий папір, лосось, крем-сир, огірок, пекінська капуста, ікра тобіко, світ чилі, кумкват',
        price: '289',
        photo: '/img/sushi/5003_front_mini.jpeg',
        category: '1'
      },
      {
        article: '5014',
        name: 'Філадельфія з тунцем',
        weight: '320',
        description: 'Рис, норі, крем-сир, огірок, авокадо, тунець',
        price: '279',
        photo: '/img/sushi/5014_front_mini.jpeg',
        category: '3'
      },
      {
        article: '5010',
        name: 'Рол з тунцем',
        weight: '355',
        description: 'Рис, крем-сир, огірок, тунець, манго, світ чилі, унагі, тагараші, кумкват',
        price: '289',
        photo: '/img/sushi/5010_front_mini.jpeg',
        category: '3'
      },
      {
        article: '5007',
        name: 'Золотий дракон',
        weight: '355',
        description: 'Рис, норі, вугор, огірок, авокадо, зелена цибуля, ікра тобіко, соус спайсі, лосось, унагі, томаго',
        price: '439',
        photo: '/img/sushi/5007_front_mini.jpeg',
        category: '4'
      },
      {
        article: '5006',
        name: 'Тигровий дракон',
        weight: '375',
        description: 'Рис, норі, креветка, огірок, авокадо, зелена цибуля, соус спайсі, вугор, томаго, ікра тобіко',
        price: '415',
        photo: '/img/sushi/5006_front_mini.jpeg',
        category: '4'
      },
      {
        article: '5013',
        name: 'Філадельфія з вугрем',
        weight: '320',
        description: 'Рис, норі, крем-сир, огірок, авокадо, вугор',
        price: '309',
        photo: '/img/sushi/5013_front_mini.jpeg',
        category: '4'
      },
      {
        article: '5012',
        name: 'Смажений з креветкою',
        weight: '260',
        description: 'Рис, норі, крем-сир, креветка, моцарела, ікра тобіко, кляр панко, соус спайсі',
        price: '235',
        photo: '/img/sushi/5012_front_mini.jpeg',
        category: '5'
      },
      {
        article: '5015',
        name: 'Філадельфія з креветкою',
        weight: '320',
        description: 'Рис, норі, крем-сир, огірок, авокадо, креветка',
        price: '335',
        photo: '/img/sushi/5015_front_mini.jpeg',
        category: '5'
      },
      {
        article: '5004',
        name: 'Сет гунканів',
        weight: '570',
        description: 'Рис, норі, лосось, тунець, вугор, краб, ікра тобіко, соус спайсі, соус світ чилі',
        price: '730',
        photo: '/img/sushi/5004_front_mini.jpeg',
        category: '6'
      },
      {
        article: '5001',
        name: 'Рол Снікерс',
        weight: '255',
        description: 'Крем-сир, Нутелла, банан, топінг полуниця, полуниця',
        price: '279',
        photo: '/img/sushi/5001_front_mini.jpeg',
        category: '7'
      },
        {
          article: '5040',
          name: 'Напій Caycen SAGIKO 320 мл',
          weight: '',
          description: '',
          price: '120',
          photo: '/img/sushi/5040_front_mini.png',
          category: '12'
        },
        {
          article: '5041',
          name: 'Напій Тамаринд SAGIKO 320 мл',
          weight: '',
          description: '',
          price: '120',
          photo: '/img/sushi/5041_front_mini.png',
          category: '12'
        },
        {
          article: '5042',
          name: 'Напій Маракуйя SAGIKO 320 мл',
          weight: '',
          description: '',
          price: '120',
          photo: '/img/sushi/5042_front_mini.png',
          category: '12'
        },
        {
          article: '5043',
          name: 'Напій Рожева Гуава SAGIKO 320 мл',
          weight: '',
          description: '',
          price: '120',
          photo: '/img/sushi/5043_front_mini.png',
          category: '12'
        },
        {
          article: '5044',
          name: 'Напій Манго SAGIKO 320 мл',
          weight: '',
          description: '',
          price: '120',
          photo: '/img/sushi/5044_front_mini.png',
          category: '12'
        },
        {
          article: '5045',
          name: 'Пепсі 0.33 скло',
          weight: '',
          description: '',
          price: '60',
          photo: '/img/sushi/5045_front_mini.png',
          category: '12'
        },
        {
          article: '5046',
          name: 'Пепсі чорна 0.25 скло',
          weight: '',
          description: '',
          price: '60',
          photo: '/img/sushi/5046_front_mini.png',
          category: '12'
        },
        {
          article: '5047',
          name: '7up 0.25 скло',
          weight: '',
          description: '',
          price: '50',
          photo: '/img/sushi/5047_front_mini.png',
          category: '12'
        },
        {
          article: '5048',
          name: 'Mirinda 0.25 скло',
          weight: '',
          description: '',
          price: '50',
          photo: '/img/sushi/5048_front_mini.png',
          category: '12'
        },
        {
          article: '5049',
          name: 'Карпатська Джерельна газ 0.5 скло',
          weight: '',
          description: '',
          price: '100',
          photo: '/img/sushi/5049_front_mini.png',
          category: '12'
        },
        {
          article: '5050',
          name: 'Карпатська Джерельна н/газ 0.5 скло',
          weight: '',
          description: '',
          price: '100',
          photo: '/img/sushi/5050_front_mini.png',
          category: '12'
        },
        {
          article: '5051',
          name: 'Сік Сандора апельсин 0.5',
          weight: '',
          description: '',
          price: '80',
          photo: '/img/sushi/5051_front_mini.png',
          category: '12'
        },
        {
          article: '5052',
          name: 'Сік Сандора мультивітамін 0.5',
          weight: '',
          description: '',
          price: '80',
          photo: '/img/sushi/5052_front_mini.png',
          category: '12'
        },
        {
          article: '5053',
          name: 'Сік Сандора томат 0.5',
          weight: '',
          description: '',
          price: '80',
          photo: '/img/sushi/5053_front_mini.png',
          category: '12'
        },
        {
          article: '5054',
          name: 'Сік Сандора яблучний 0.5',
          weight: '',
          description: '',
          price: '80',
          photo: '/img/sushi/5054_front_mini.png',
          category: '12'
        },
        {
          article: '5061',
          name: 'Креветка попкорн',
          weight: '162',
          description: 'Креветка, борошно, олія соняшникова, розпушувач, соус солодкий чилі, соус унагі',
          price: '170',
          photo: '/img/sushi/5061_front_mini.jpeg',
          category: '8'
        },
        {
          article: '5062',
          name: 'Фіш боул',
          weight: '300',
          description: 'Лосось, цибуля синя, яйце куряче, панко, олія соняшникова, борошно, соус спайсі, соус унагі',
          price: '280',
          photo: '/img/sushi/5062_front_mini.jpeg',
          category: '8'
        },
        {
          article: '5063',
          name: 'Тяхан з лососем',
          weight: '350',
          description: 'Рис, болгарський перець, морква, цибуля синя, цибуля зелена, огірок, кунжут, яйце куряче, соус солодкий чилі, соус унагі, соєвий соус, устричний соус, лосось',
          price: '280',
          photo: '/img/sushi/5063_front_mini.jpeg',
          category: '8'
        },
        {
          article: '5064',
          name: 'Фунчоза з креветкою',
          weight: '330',
          description: 'Локшина фунчоза, цибуля синя, морква, перець болгарський, цибуля зелена, огірок, кунжут, соєвий соус, соус унагі, соус солодкий чилі, креветка',
          price: '330',
          photo: '/img/sushi/5064_front_mini.jpeg',
          category: '8'
        },
        {
          article: '5065',
          name: 'Локшина соба з лососем',
          weight: '330',
          description: 'Локшина соба, перець болгарський, цибуля синя, морква, цибуля зелена, огірок, соус унагі, соєвий соус, соус солодкий чилі, лосось',
          price: '280',
          photo: '/img/sushi/5065_front_mini.jpeg',
          category: '8'
        },

        {
          article: '5066',
          name: 'Футомакі з лососем',
          weight: '240',
          description: 'Рис, норі, крем-сир, огірок, лосось, соус спайсі',
          price: '215',
          photo: '/img/sushi/5066_front_mini.jpeg',
          category: '1'
        },

        {
          article: '5067',
          name: 'Тяхан з креветкою',
          weight: '350',
          description: 'Рис, перець болгарський, морква, цибуля синя, цибуля зелена, огірок, кунжут, яйце куряче, соус солодкий чилі, соус унагі, соєвий соус, устричний соус, креветка',
          price: '270',
          photo: '/img/sushi/5067_front_mini.jpeg',
          category: '8'
        },
        {
          article: '5068',
          name: 'Фунчоза з лососем',
          weight: '330',
          description: 'Локшина фунчоза, цибуля синя, морква, перець болгарський, цибуля зелена, огірок, кунжут, соєвий соус, соус унагі, соус солодкий чилі, лосось',
          price: '330',
          photo: '/img/sushi/5068_front_mini.jpeg',
          category: '8'
        },
        {
          article: '5069',
          name: 'Локшина соба з креветкою',
          weight: '330',
          description: 'Локшина соба, перець болгарський, цибуля синя, морква, цибуля зелена, огірок, соус унагі, соєвий соус, соус солодкий чилі, креветка',
          price: '280',
          photo: '/img/sushi/5069_front_mini.jpeg',
          category: '8'
        },
        {
          article: '5060',
          name: 'Вогняний дракон',
          weight: '370',
          description: 'Рис, норі, манго, огірок, крем-сир, лосось, унагі, вугор, тобіко, перець чилі',
          price: '420',
          photo: '/img/sushi/5060_front_mini.jpeg',
          category: '1'
        },
        {
          article: '5070',
          name: 'Сет Токаш',
          weight: '1250',
          description: 'Рол з тунцем, спрінг рол з лососем, макі рол з огірком, каліфорнія з вугрем, тобіко, нігірі, футомакі з лососем, імбир, васабі',
          price: '1159',
          photo: '/img/sushi/5070_front_mini.jpeg',
          category: '13'
        },
        {
          article: '5071',
          name: 'Сет Філадельфія мікс',
          weight: '720',
          description: 'Рол філадельфія мікс: лосось та тунець + Рол філадельфія мікс: креветка та вугор + Рол філадельфія з авокадо',
          price: '780',
          photo: '/img/sushi/5071_front_mini.jpeg',
          category: '13'
        },
        {
          article: '5072',
          name: 'Рол Токаш',
          weight: '480',
          description: 'Рис, Норі, Крем-сир, Креветка, Яйце куряче, Панко, Огірок, Краб, Лосось, Ікра Тобіко, Кумкват, Устричний соус',
          price: '420',
          photo: '/img/sushi/5072_front_mini.jpeg',
          category: '1'
        },
        {
          article: '5073',
          name: 'РолДог',
          weight: '460',
          description: 'Рис, норі, крем-сир, тостовий сир, огірок, креветка, крабова паличка, соус спайсі, соус унагі, панко, яйце куряче, борошно, олія соняшникова',
          price: '315',
          photo: '/img/sushi/5073_front_mini.jpeg',
          category: '5'
        },
        {
          article: '5074',
          name: 'Рол від Шефа',
          weight: '430',
          description: 'Рис, норі, креветка, крем-сир, томаго, вугор, соус унагі, майонез, трюфельна сальса, стружка кокоса, огірок',
          price: '420',
          photo: '/img/sushi/5074_front_mini.jpeg',
          category: '4'
        },
        {
          article: '5075',
          name: 'Рол Баунті',
          weight: '290',
          description: 'Тісто листове, крем-сир, кокосова стружка, пудра сахарна, банан, карамельний топінг',
          price: '239',
          photo: '/img/sushi/5075_front_mini.jpeg',
          category: '7'
        },
        {
          article: '5078',
          name: 'Лимонад "Зандукелі" Груша 0.5 л',
          weight: '',
          description: '',
          price: '100',
          photo: '/img/sushi/5078_front_mini.jpeg',
          category: '12'
        },
        {
          article: '5079',
          name: 'Лимонад "Зандукелі" Сапераві 0.5 л',
          weight: '',
          description: '',
          price: '100',
          photo: '/img/sushi/5079_front_mini.jpeg',
          category: '12'
        },
        {
          article: '5080',
          name: 'Лимонад "Зандукелі" Тархун 0.5 л',
          weight: '',
          description: '',
          price: '100',
          photo: '/img/sushi/5080_front_mini.jpeg',
          category: '12'
        },
        {
          article: '5555',
          name: 'Пакет',
          weight: '2',
          description: 'Пакет',
          price: '10',
          photo: '/img/sushi/5555_front.jpeg',
          category: '14'
        }
      ],
    };
  },
  props: {
    msg: String
  },
  created() {
    //this.fetchAndParseSushiData();

  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
     window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      console.log("addEventListener beforeinstallprompt")
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
    });
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
  //   parseSushiData(sushiData) {
  //   return sushiData.map(item => {
  //     return {
  //       article: item.id ? item.id.toString() : '', // Handle null or undefined id
  //       name: item.nameUa || '', // Fallback to empty string if nameUa is null
  //       weight: item.weight ? item.weight.toString() : '', // Handle null or undefined weight
  //       description: item.descriptionUa || '', // Fallback to empty string if descriptionUa is null
  //       price: item.price ? item.price.toString() : '', // Handle null or undefined price
  //       photo: item.imgMiddle || '', // Fallback to empty string if imgMiddle is null
  //       category: item.category || '' // Fallback to empty string if category is null
  //     };
  //   });
  // },
    // fetchAndParseSushiData() {
    //   try {
    //   axios.get('https://api.sushitokash.com.ua/api/sushi-items')
    //     .then(response => {
    //       this.sushiList = this.parseSushiData(response.data);
    //       console.log(this.sushiList);
    //     })
    //     .catch(error => {
    //       console.error("There was an error fetching the sushi items:", error);
    //     });
    //   } catch (error) {
    //                 console.error('Cannot update items:'); 
    //             }
    // },
    filteredSushiList(categories) {
    // Ensure categories is an array, even if a single category is passed
      if (!Array.isArray(categories)) {
        categories = [categories];
      }
      return this.sushiList.filter(item => categories.includes(item.category));
    },

     addToCart(item) {
      this.$emit('add-to-cart', item);
    },
    // addToCart(article) {
    //   const articleStr = String(article); // Convert article to string
    //   console.log("addToCart called with article:", articleStr);
    //   console.log("Current sushiList:", this.sushiList);

    //   this.sushiList.forEach(sushi => {
    //     console.log("Sushi article:", sushi.article);
    //   });

    //   const item = this.sushiList.find(sushi => String(sushi.article) === articleStr); // Ensure comparison is between strings
    //   console.log("Found item:", item);

    //   if (item) {
    //     this.$emit('add-to-cart', item);
    //   } else {
    //     console.error("Item not found in sushiList for article:", articleStr);
    //   }
    // },

    installPWA() {
      console.log("install PWa")
      if (this.deferredPrompt) {
        this.deferredPrompt.prompt();
        this.deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the A2HS prompt');
          } else {
            console.log('User dismissed the A2HS prompt');
          }
          this.deferredPrompt = null;
        });
      }
    },

    handleClick() {
      // Initiate a call
      window.location.href = 'tel:+380800338105';
      
      // Navigate to the sushi item
    },
    handleScroll() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if (scrollTop < 100) {
        // Less than 100px from the top, show the menu
        this.menuVisible = true;
      } else if (scrollTop > this.lastScrollTop) {
        // Scrolling down, hide the menu
        this.menuVisible = false;
      } else {
        // Scrolling up, show the menu
        this.menuVisible = true;
      }

      this.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // For Mobile or negative scrolling
    },
  
    scrollToSection(sectionId) {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    },
    navigateToSushiItem(article) {
      this.$router.push({ name: 'SushiItem', params: { article } });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.sushi-types-container {
  width: 100%;
  /* background-color: #333; */
  padding: 5px 0px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
  position: fixed;
  top: 0;
  z-index: 2;
  transition: transform 0.3s ease;
  transform: translateY(-100%); /* Hide the menu by default */
}

.sushi-types-container.visible {
  transform: translateY(0); /* Show the menu when visible */
}

.sushi-types {
  display: flex;
  justify-content: space-between;
  width: 60%;  /* Adjust this value as needed */
  margin: 0 auto;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
}
.sushi-types::-webkit-scrollbar {
  display: none; /* Hide scrollbar in WebKit browsers */
}

.sushi-types a {
  text-decoration: none;
  color: inherit;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}

.sushi-types a img {
  width: 40px;
  height: 40px;
  margin-bottom: 5px;
}

.sushi-types a span {
  margin-top: 5px;
  font-size: 14px;
  color: #E9E9E9;
}

@media (max-width: 700px) {
  .sushi-types {
    width: 86%;
    overflow-x: auto;
    gap: 10px;
  }
  .sushi-types::-webkit-scrollbar {
    display: none; /* Hide scrollbar in WebKit browsers */
  }
}

@media (max-width: 500px) {
  .sushi-types {
    width: 100%;
    overflow-x: auto;
    padding: 5px 15px;
  }
  .sushi-types::-webkit-scrollbar {
    display: none; /* Hide scrollbar in WebKit browsers */
  }

  .sushi-types a img {
    width: 30px;
    height: 30px;
    margin-bottom: 5px;
    font-size: 12px;
  }
  .sushi-types a span {
    font-size: 13px;
  }
}

@media (max-width: 380px) {
  .sushi-types {
    width: 100%;
    overflow-x: auto;
    padding: 5px 3px;
    gap: 7px;
  }
  .sushi-types::-webkit-scrollbar {
    display: none; /* Hide scrollbar in WebKit browsers */
  }

  .sushi-types a img {
    width: 27px;
    height: 27px;
    margin-bottom: 5px;
    font-size: 12px;
  }
  .sushi-types a span {
    font-size: 12px;
  }
}

@media (max-width: 330px) {
  .sushi-types {
    width: 100%;
    overflow-x: auto;
    padding: 5px 0px;
  }
  .sushi-types::-webkit-scrollbar {
    display: none; /* Hide scrollbar in WebKit browsers */
  }

  .sushi-types a img {
    width: 27px;
    height: 27px;
    margin-bottom: 5px;
    font-size: 12px;
  }
  .sushi-types a span {
    font-size: 12px;
  }
}

.f-margin {
  margin-top: 90px !important;
}

/* Additional styles for the sushi sections and items */
.sushi-section {
  margin: 20px auto;
  padding: 20px;
  width: 80%;
  padding-top: 60px; /* Adjust to avoid overlapping with the fixed header */
}

.sushi-section h2 {
  color: #E9E9E9;
  margin-bottom: 20px;
  text-align: left;
}

.sushi-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.sushi-item {
  cursor: pointer;
  background-color: #333;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.sushi-item .article-number {
  display: block;
  margin-left: 10px;
  color: #FF5C5C;
  font-size: 0.9em;
}

.sushi-item img {
  width: 100%;
  height: auto;
}

.sushi-item p {
  padding: 10px;
  color: #E9E9E9;
}

.sushi-item h3 {
  padding: 10px;
  color: #E9E9E9;
}

.button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.price {
  font-size: 18px;
  color: #FF5C5C;
  font-weight: bold;
}

.sushi-item button {
  background-color: #88c057;  /* Light green background color */
  color: #333;  /* Darker text color for contrast */
  border: none;
  border-radius: 20px;
  padding: 10px;
  margin-left: 10px;
  cursor: pointer;
  width: 100px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  position: relative;
}

.sushi-item button:hover {
  background-color: #7bb249;  /* Slightly darker green on hover */
}

.sushi-item button img {
  width: 20px;
  height: 20px;
  margin-left: 5px;
}

.sushi-item .weight {
  color: #FF5C5C;
  margin-bottom: 5px;
  font-size: 1em;
  font-weight: bold;
}


.section-map {
      width: 100%;
      margin: 0 auto;
      background-color: #353535;
      border-radius: 10px;
      overflow: hidden;
      padding-bottom: 40px;
      box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    }

    .section-map-header {
      text-align: center;
      padding: 40px 20px;
      background-color: #353535; /* Soft pink background */
    }

    .section-map-header h2 {
      font-size: 32px;
      margin-bottom: 10px;
      color: #E9E9E9; /* Deep red color */
    }

    .section-map-header p {
      font-size: 18px;
      color: #555;
    }

    .map-container {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 aspect ratio */
      height: 0;
      margin-left: 80px;
      margin-right: 80px;
      overflow: hidden;
    }

    .map-container iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: none;
    }

    .contact-info {
      display: flex;
      flex-wrap: wrap;
      padding: 40px 20px;
      background-color: #fff;
    }

    .contact-item {
      flex: 1 1 300px;
      margin: 20px;
      text-align: center;
    }

    .contact-item h3 {
      font-size: 24px;
      margin-bottom: 10px;
      color: #d13f3f;
    }

    .contact-item p {
      font-size: 16px;
      color: #555;
    }

    @media (max-width: 768px) {
      .section-map-header h2 {
        font-size: 24px;
      }

      .section-map-header p {
        font-size: 16px;
      }

      .contact-item h3 {
        font-size: 20px;
      }

      .contact-item p {
        font-size: 14px;
      }
      .map-container {
        position: relative;
        padding-bottom: 70.25%; /* 16:9 aspect ratio */
        height: 0;
        margin-left: 50px;
        margin-right: 50px;
        overflow: hidden;
      }
    }
      @media (max-width: 450px) {
        .map-container {
        position: relative;
        padding-bottom: 100.25%; /* 16:9 aspect ratio */
        height: 0;
        margin-left: 10px;
        margin-right: 10px;
        overflow: hidden;
      }

      }

      .g-weight{
         font-size: 14px; /* Adjust the size */
          color: #FF5C5C !important; /* Lighter color for secondary information */
          /* margin-top: 5px; Spacing from the above element */
          font-weight: bold; /* Bold for emphasis */
          margin: 0px;
        }
      .m-b {
        margin-bottom: 5px;
      }
      .m-t {
        margin-top: 5px;
      }
    

</style>
